import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import masterlifeHero from '../images/masterlifeHero.png'
import playstore from '../images/playstore.svg'
import appstore from '../images/appstore.svg'
import final_masterlife from '../videos/final_masterlife.mp4'
import ml1 from '../images/ml1.svg'
import ml2 from '../images/ml2.svg'
import ml3 from '../images/ml3.svg'
import SEO from '../components/seo'

function Masterlife() {
    return (
        <StyledWrapper>
            <Layout>
                <SEO title="Masterlife Case Study"
                     description="Masterlife is a companion app for people looking to make positive change in their lives."
                     path="/masterlife"
                     pageImage={masterlifeHero}
                />
                <div className="masterlife">
                    <div className="masterlife__main">
                        <div className="masterlife__herotext">
                            <h5>CASE STUDY</h5>
                            <hr className="masterlife__line"/>
                            <div className="masterlife__title">
                                <h5>MASTERLIFE</h5>
                                <div className="case__study__page__title d-sm-block d-none">
                                    A companion app for people looking to make positive change in their lives
                                </div>
                            </div>
                        </div>
                        <img src={masterlifeHero} alt=""/>
                        <div className="page__titleMobile d-sm-none d-block">
                            A companion app for people looking to make positive change in their lives
                        </div>
                        <div className="row masterlife__details">
                            <div className="masterlife__store col-sm-6 col-12">
                                <h5>Masterlife structures pathways for people to positively change their habits in a
                                    conscious and
                                    purposeful manner. It address 8 areas of life, ranging from personal growth to
                                    business and finance,
                                    and offers 30-day courses to self improve. Through a range of different assessments,
                                    Masterlife recommends the most relevant courses that will benefit the user. The
                                    primary challenge for
                                    us was to develop a design system that is modular and flexible for ever-changing
                                    startup goals.
                                </h5>
                                <div className="store">
                                    <a href="https://play.google.com/store/apps/details?id=com.masterlife.masterlife"
                                       target="_blank" rel="noreferrer">
                                        <img src={playstore} alt=""/>
                                    </a>
                                    <a href="https://apps.apple.com/in/app/masterlife/id1497561109" targrt="_blank"
                                       rel="noreferrer">
                                        <img src={appstore} alt=""/>
                                    </a>
                                </div>
                            </div>
                            <div className="masterlife__tools col-sm-6 col-12">
                                <span>
                                   <h4>What We Did:</h4>
                                   <h5>UX/Visual Design & Development</h5>
                                </span>
                                <span>
                                   <h4>Built With:</h4>
                                   <h5> Flutter, NodeJS, React</h5>
                                </span>
                                <span>
                                   <h4>Hosting:</h4>
                                   <h5>Firebase, Google Cloud Platform</h5>
                                </span>
                                <span>
                                   <h4>Payment:</h4>
                                   <h5>In-App Purchase</h5>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="context">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-4">
                                    <div className="number-text-block">
                                        <p className="cs-number">1</p>
                                        <hr className="cs-hr-line"/>
                                        <h5 className="cs-subtext">CONTEXT &amp; INSIGHTS</h5>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8 cs-left-right-border">
                                    <h5 className="mb-1">The team analysed an existing landscape of products in the
                                        self-help area. We also
                                        examined the Masterlife target group specifically, to gain a better and
                                        contextual understanding.
                                    </h5>
                                    <h5 className="mb-5">Through collaborative workshops with the client and interviews
                                        with the target group, we filtered
                                        down all the insights into a manageable list. </h5>
                                    <div className="container">
                                        <div className="row mb-2 masterlife__context">
                                            <div className="col-3">
                                                <img src={ml1} alt=""/>
                                            </div>
                                            <div className="col-9 mb-4">
                                                <h5>“My biggest issue is focus. Whenever I start something, I go
                                                    all out but then eventually I lose the spark. For example, I was
                                                    reading seriously last
                                                    year for about 6 months but then it fizzled out. It’s as if I need
                                                    constant motivation”
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="row mb-2 masterlife__context">
                                            <div className="col-3">
                                                <img src={ml2} alt=""/>
                                            </div>
                                            <div className="col-9 mb-4">
                                                <h5>“Once I joined a weight loss program on an app, but I struggled
                                                    to follow the instructions. It was very generalised, and some of the
                                                    food
                                                    recommendations were alien to me”</h5>
                                            </div>
                                        </div>
                                        <div className="row mb-2 masterlife__context">
                                            <div className="col-3">
                                                <img src={ml3} alt=""/>
                                            </div>
                                            <div className="col-9 mb-4">
                                                <h5>“I am not social enough and because I work from home I have
                                                    unhealthy eating habits. I often feel like others are doing more to
                                                    then me, and I don’t
                                                    know where to start doing something about it”</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="callouts">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 col-md-8 callouts__list">
                                    <h5>Callouts:</h5>
                                    <ol>
                                        <li>Varied assessments to help identify gaps and opportunities</li>
                                        <li>Self-paced that hand-hold the user as they engage</li>
                                        <li>Group programs that help motivate the user to complete daily tasks</li>
                                        <li>Everyday reminders and notifications</li>
                                        <li>Video and Book Summaries</li>
                                        <li>Subscriptions and single course purchase</li>
                                        <li>Admin panel for content upload built with React</li>
                                    </ol>
                                </div>
                                <div className="col-lg-3 col-md-4 cs-left-right-border">
                                    <div className="number-text-block">
                                        <p className="cs-number">2</p>
                                        <hr className="cs-hr-line"/>
                                        <p className="cs-subtext">THE APP</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <video autoplay="autoplay" loop="loop" muted="muted" playsinline="" className="showreel w-100"
                           src={final_masterlife}></video>
                    <div className="masterlife__review">
                        <div className="container">
                            <h3>Anirudh Narayan</h3>
                            <h6 className="mb-4">CEO, Masterlife</h6>
                            <h5>“Honestly if you want to create a global product with a strong focus on
                                UI/UX, you should definitely collaborate with 1st Main. They're professional, highly
                                process driven
                                and their integrity is high. They might be one of the few dev shops in India that are
                                extremely
                                reliable and can deliver end to end products. Both Kiran &amp; Kinnari along with their
                                team bring a lot
                                of their global experience into the day to day so that's key.”</h5>
                        </div>
                    </div>
                </div>
            </Layout>
        </StyledWrapper>
    )
}

export default Masterlife

const StyledWrapper = styled.div`
  .masterlife {
    transition: 750ms;
    height: 100%;
  }

  .masterlife__main {
    padding: 150px 100px 100px 100px;
    background-image: linear-gradient(180deg, #7d389c 0%, #c2237d 100%)
  }

  .masterlife__herotext {
    display: flex;
  }

  .masterlife__herotext > h5 {
    font-weight: 600;
    color: white;
    width: 20%;
  }

  .masterlife__title {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
  }

  .masterlife__title > h5 {
    font-weight: 600;
    color: white;
  }

  .masterlife__herotext > hr {
    border: 1px solid white;
  }

  .masterlife__main > img {
    display: block;
    margin: 100px auto 100px auto;
    padding-left: 50px;
    padding-right: 50px;
  }

  .masterlife__tools > h5 {
    display: flex;
    flex-direction: column;
  }

  .masterlife__tools > span > h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }

  .masterlife__store > h5, .masterlife__tools > span > h5, h4 {
    color: white;
  }

  .masterlife__store > h5, .masterlife__tools > span > h5 {
    margin-bottom: 32px;
    font-weight: 300;
  }

  .store > a {
    padding-right: 20px;
  }

  a::before {
    display: none !important;
  }

  .masterlife__details {
    padding: 50px 15px 0px 100px;
  }

  .context {
    margin-top: 100px;
  }

  .number-text-block {
    text-align: center;
    margin-top: 50px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 30px;
    order: 1;
  }

  .masterlife__context > div > img {
    height: 200px;
    object-fit: contain;
  }

  .masterlife__context > div {
    display: flex;
    align-items: center;
  }

  .masterlife__context > div > h5 {
    max-width: 80%;
    font-weight: 400;
  }

  .cs-left-right-border {
    border-left: solid 2px #F6F6F6
  }

  .cs-number {
    color: #3e363d;
    font-size: 130px;
    font-weight: 700;
    line-height: 33px;
    padding-bottom: 30px;
  }

  .cs-hr-line {
    background-color: #4A4BC7;
    max-width: 100%;
    width: 200px;
  }

  .callouts__list > ol > li {
    list-style: numbers !important;
    padding: 5px 0px;
    font-size: 20px;
    font-weight: lighter;
  }

  .callouts__list > h5 {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 600;
  }

  .callouts {
    padding: 100px 0px;
  }

  .masterlife__review > div > p {
    margin-bottom: 0px;
  }

  .masterlife__review > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 200px 0px;
  }

  .masterlife__review > .container > p {
    width: 80%;
    text-align: center;
  }

  .masterlife__review > .container > h3 {
    color: #296251;
  }

  .masterlife__review > .container > h5 {
    width: 80%;
    font-size: 25px;
    font-weight: lighter;
    text-align: center;
    line-height: 40px;
  }

  .masterlife__line {
    width: 50%;
  }

  @media (max-width: 992px) {
    .masterlife {
      border-left: none;
    }

    .masterlife__main {
      padding: 150px 20px 50px;
    }

    .masterlife__main > img {
      display: block;
      margin: 20px auto 20px auto;
      padding-left: 10px;
      padding-right: 10px;
      height: 300px;
      object-fit: cover;
    }

    .masterlife__details {
      padding: 0px 15px 50px 50px;
    }

    .masterlife__store {
      padding: 20px 0px;
    }

    .masterlife__tools {
      padding: 50px 0px 0px 0px;
    }

    .masterlife__context > div > img {
      height: 60px;
      object-fit: contain;
    }

    .masterlife__context > div > h5 {
      max-width: 100%;
      font-weight: 400;
    }

    .callouts > .container > .row {
      display: flex;
      flex-direction: column-reverse;
    }

    .cs-left-right-border {
      padding-bottom: 20px;
    }

    .masterlife__review > .container > h5 {
      font-size: 18px;
      line-height: 30px;
    }

    .masterlife__line {
      width: 20%;
    }

    .masterlife__herotext {
      display: flex;
      width: 100%;
      text-align: center;
    }

    .page__titleMobile {
      font-size: 30px;
      color: white;
      padding-top: 50px;
      line-height: 40px;
    }

    .masterlife__herotext > h5 {
      width: 34%;
    }

    .masterlife__review > .container {
      padding: 100px 0px;
    }

    .masterlife__herotext > hr {
      border: 0.5px solid white;
      width: 30%;
    }

    .masterlife__title {
      padding-left: 0px;
    }

  }
`